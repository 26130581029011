import React, { Fragment, useEffect, useState } from "react";
import useInput from "../../hooks/use-input";
import {
  Stack,
  FormControl,
  InputLabel,
  Button,
  InputAdornment,
  Typography,
  FilledInput,
  FormHelperText,
  MenuItem,
  Select,
  Divider,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import FileUploader from "../UI/FileUploader";

const ModelForm = ({
  action,
  model,
  translationModel,
  token,
  onEdit,
  onUpdate,
  onCancel,
}) => {
  const { t } = useTranslation("common");
  const [timezones, setTimezones] = useState("");
  let loadForm = true;
  const countries = require("../../translations/es/countries.json"); //TODO: Fetch according to lang. settings
  const momentTimezones = require("moment-timezone/data/meta/latest.json");
  const mainTimeZones = Object.values(momentTimezones.zones);

  const getTimeZonesByCountry = (countryCode) => {
    return mainTimeZones.filter((zone) => zone.countries.includes(countryCode));
  };

  const createCountry = (name, code, index) => {
    return (
      <MenuItem key={index} value={code}>
        {name}
      </MenuItem>
    );
  };

  const createTimezones = (name, index) => {
    return (
      <MenuItem key={index} value={name}>
        {name}
      </MenuItem>
    );
  };

  const {
    enteredValue: clientId,
    setEnteredValue: setClientId,
    reset: resetClientId,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: companyName,
    setEnteredValue: setCompanyName,
    valueIsValid: companyNameIsValid,
    valueChangeHandler: companyNameChangeHandler,
    hasError: companyNameHasError,
    inputBlurHandler: companyNameBlurHandler,
    reset: resetCompanyName,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: companyId,
    setEnteredValue: setCompanyId,
    valueChangeHandler: companyIdChangeHandler,
    inputBlurHandler: companyIdBlurHandler,
    reset: resetCompanyId,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: companyAddress,
    setEnteredValue: setCompanyAddress,
    valueChangeHandler: companyAddressChangeHandler,
    inputBlurHandler: companyAddressBlurHandler,
    reset: resetCompanyAddress,
  } = useInput();

  const {
    enteredValue: companyEmail,
    setEnteredValue: setCompanyEmail,
    valueChangeHandler: companyEmailChangeHandler,
    inputBlurHandler: companyEmailBlurHandler,
    reset: resetCompanyEmail,
  } = useInput();

  const {
    enteredValue: companyPhone,
    setEnteredValue: setCompanyPhone,
    valueChangeHandler: companyPhoneChangeHandler,
    inputBlurHandler: companyPhoneBlurHandler,
    reset: resetCompanyPhone,
  } = useInput();

  const {
    enteredValue: companyWebsite,
    setEnteredValue: setCompanyWebsite,
    valueChangeHandler: companyWebsiteChangeHandler,
    inputBlurHandler: companyWebsiteBlurHandler,
    reset: resetCompanyWebsite,
  } = useInput();

  const {
    enteredValue: instagram,
    setEnteredValue: setInstagram,
    valueChangeHandler: instagramChangeHandler,
    inputBlurHandler: instagramBlurHandler,
    reset: resetInstagram,
  } = useInput();

  const {
    enteredValue: facebook,
    setEnteredValue: setFacebook,
    valueChangeHandler: facebookChangeHandler,
    inputBlurHandler: facebookBlurHandler,
    reset: resetFacebook,
  } = useInput();

  const {
    enteredValue: youtube,
    setEnteredValue: setYoutube,
    valueChangeHandler: youtubeChangeHandler,
    inputBlurHandler: youtubeBlurHandler,
    reset: resetYoutube,
  } = useInput();

  const {
    enteredValue: country,
    setEnteredValue: setCountry,
    inputBlurHandler: countryBlurHandler,
    reset: resetCountry,
  } = useInput();

  const countryChangeHandler = (event) => {
    setCountry(event.target.value);
    resetTimezone();
    setTimezones(getTimeZonesByCountry(event.target.value));
  };

  const {
    enteredValue: timezone,
    setEnteredValue: setTimezone,
    valueChangeHandler: timezoneChangeHandler,
    inputBlurHandler: timezoneBlurHandler,
    reset: resetTimezone,
  } = useInput();

  const {
    enteredValue: logo,
    setEnteredValue: setLogo,
    reset: resetLogo,
  } = useInput();

  const {
    enteredValue: ticketLabel,
    setEnteredValue: setTicketLabel,
    valueChangeHandler: ticketLabelChangeHandler,
    inputBlurHandler: ticketLabelBlurHandler,
    reset: resetTicketLabel,
  } = useInput();

  const {
    enteredValue: termsAndConditions,
    setEnteredValue: setTermsAndConditions,
    valueChangeHandler: termsAndConditionsChangeHandler,
    inputBlurHandler: termsAndConditionsBlurHandler,
    reset: resetTermsAndConditions,
  } = useInput();

  const [printLogoOnTicket, setPrintLogoOnTicket] = useState(false);
  const printLogoOnTicketChangeHandler = () => {
    setPrintLogoOnTicket(!printLogoOnTicket);
  };
  const resetPrintLogoOnTicket = () => {
    setPrintLogoOnTicket("");
  };

  const [taxationAfterDiscount, setTaxationAfterDiscount] = useState(false);
  const taxationAfterDiscountChangeHandler = () => {
    setTaxationAfterDiscount(!taxationAfterDiscount);
  };
  const resetTaxationAfterDiscount = () => {
    setTaxationAfterDiscount("");
  };

  const [sendMailNotifications, setSendMailNotifications] = useState(false);
  const sendMailNotificationsChangeHandler = () => {
    setSendMailNotifications(!sendMailNotifications);
  };
  const resetSendMailNotifications = () => {
    setSendMailNotifications("");
  };
  // TODO: Enable field at API to set tax before or after discount

  let formIsValid = false;

  if (companyNameIsValid) {
    formIsValid = true;
  }

  const formEditHandler = () => {
    onEdit();
  };

  const formSubmitHandler = async (event) => {
    event.preventDefault();

    let printLogoOnTicketValue = null;
    if (printLogoOnTicket === true) {
      printLogoOnTicketValue = 1;
    }
    if (printLogoOnTicket === false) {
      printLogoOnTicketValue = null;
    }

    let taxationAfterDiscountValue = null;
    if (taxationAfterDiscount === true) {
      taxationAfterDiscountValue = 1;
    }
    if (taxationAfterDiscount === false) {
      taxationAfterDiscountValue = null;
    }

    let sendMailNotificationsValue = null;
    if (sendMailNotifications === true) {
      sendMailNotificationsValue = 1;
    }
    if (sendMailNotifications === false) {
      sendMailNotificationsValue = null;
    }

    if (action === "edit") {
      const modelData = {
        id: model.company.id,
        comCommercialName: companyName,
        comRFC: companyId,
        comCommercialAddress: companyAddress,
        comMainEmail: companyEmail,
        comTels: companyPhone,
        comURL: companyWebsite,
        comInstagram: instagram,
        comFacebook: facebook,
        comYoutube: youtube,
        comCountry: country,
        comTimeZone: timezone,
        comLogo: logo,
        comTicketLabel: ticketLabel,
        comTermsAndConditions: termsAndConditions,
        comPrintLogoTicket: printLogoOnTicketValue,
        comTaxationAfterDiscount: taxationAfterDiscountValue,
        comSendMailNotifications: sendMailNotificationsValue,
      };
      onUpdate(modelData);
    }

    resetClientId();
    resetCompanyName();
    resetCompanyId();
    resetCompanyAddress();
    resetCompanyEmail();
    resetCompanyPhone();
    resetCompanyWebsite();
    resetInstagram();
    resetFacebook();
    resetYoutube();
    resetCountry();
    resetTimezone();
    resetLogo();
    resetTicketLabel();
    resetTermsAndConditions();
    resetPrintLogoOnTicket();
    resetTaxationAfterDiscount();
    resetSendMailNotifications();
  };

  const cancelHandler = () => {
    onCancel();
  };

  const uploadSuccessHandler = (fileName) => {
    setLogo(fileName);
  };

  useEffect(() => {
    if (loadForm) {
      if (model && action) {
        setClientId(model.company?.comClientID || "");
        setCompanyName(model.company?.comCommercialName || "");
        setCompanyId(model.company?.comRFC || "");
        setCompanyAddress(model.company?.comCommercialAddress || "");
        setCompanyEmail(model.company?.comMainEmail || "");
        setCompanyPhone(model.company?.comTels || "");
        setCompanyWebsite(model.company?.comURL || "");
        setInstagram(model.company?.comInstagram || "");
        setFacebook(model.company?.comFacebook || "");
        setYoutube(model.company?.comYoutube || "");
        setCountry(model.company?.comCountry || "");
        country &&
          setTimezones(getTimeZonesByCountry(model.company?.comCountry));
        setTimezone(model.company?.comTimeZone || "");
        setLogo(model.company?.comLogo || "");
        setTicketLabel(model.company?.comTicketLabel || "");
        setTermsAndConditions(model.company?.comTermsAndConditions || "");
        setPrintLogoOnTicket(model.company?.comPrintLogoTicket || "");
        setTaxationAfterDiscount(model.company?.comTaxationAfterDiscount || "");
        setSendMailNotifications(model.company?.comSendMailNotifications || "");
      }
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model]);

  return (
    <Fragment>
      <Stack
        className={`${styles["client-form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <FormControl variant="outlined">
          <InputLabel htmlFor="clientId">
            {t(translationModel + ".form.clientId")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="clientId"
            value={clientId}
            type="text"
            label={t(translationModel + ".form.clientId")}
            disabled
          />
        </FormControl>
        <FormControl
          error={companyNameHasError ? true : false}
          variant="outlined"
        >
          <InputLabel htmlFor="companyName">
            {t(translationModel + ".form.companyName")}*
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="companyName"
            onChange={companyNameChangeHandler}
            onBlur={companyNameBlurHandler}
            value={companyName}
            endAdornment={
              companyName !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetCompanyName} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.companyName")}
            disabled={action === "view"}
          />
          {companyNameHasError && (
            <FormHelperText id="companyName-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="companyId">
            {t(translationModel + ".form.companyId")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="companyId"
            onChange={companyIdChangeHandler}
            onBlur={companyIdBlurHandler}
            value={companyId}
            endAdornment={
              companyId !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetCompanyId} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.companyId")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="companyAddress">
            {t(translationModel + ".form.companyAddress")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white" }}
            id="companyAddress"
            onChange={companyAddressChangeHandler}
            onBlur={companyAddressBlurHandler}
            value={companyAddress}
            endAdornment={
              companyAddress !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetCompanyAddress} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.companyAddress")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="companyEmail">
            {t(translationModel + ".form.companyEmail")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="companyEmail"
            onChange={companyEmailChangeHandler}
            onBlur={companyEmailBlurHandler}
            value={companyEmail}
            endAdornment={
              companyEmail !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetCompanyEmail} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.companyEmail")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="companyPhone">
            {t(translationModel + ".form.companyPhone")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="companyPhone"
            onChange={companyPhoneChangeHandler}
            onBlur={companyPhoneBlurHandler}
            value={companyPhone}
            endAdornment={
              companyPhone !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetCompanyPhone} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.companyPhone")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="companyWebsite">
            {t(translationModel + ".form.companyWebsite")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="companyWebsite"
            onChange={companyWebsiteChangeHandler}
            onBlur={companyWebsiteBlurHandler}
            value={companyWebsite}
            endAdornment={
              companyWebsite !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetCompanyWebsite} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.companyWebsite")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="instagram">
            {t(translationModel + ".form.instagram")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="instagram"
            onChange={instagramChangeHandler}
            onBlur={instagramBlurHandler}
            value={instagram}
            endAdornment={
              instagram !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetInstagram} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.instagram")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="facebook">
            {t(translationModel + ".form.facebook")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="facebook"
            onChange={facebookChangeHandler}
            onBlur={facebookBlurHandler}
            value={facebook}
            endAdornment={
              facebook !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetFacebook} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.facebook")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="youtube">
            {t(translationModel + ".form.youtube")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="youtube"
            onChange={youtubeChangeHandler}
            onBlur={youtubeBlurHandler}
            value={youtube}
            endAdornment={
              youtube !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetYoutube} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.youtube")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl>
          <InputLabel id="country_label">
            {t(translationModel + ".form.country")}
          </InputLabel>
          <Select
            id="country"
            labelId="country_label"
            value={country}
            label={t(translationModel + ".form.country")}
            onChange={countryChangeHandler}
            onBlur={countryBlurHandler}
            disabled={action === "view"}
          >
            {countries.map((country, index) =>
              createCountry(country.name, country.code, index)
            )}
          </Select>
        </FormControl>
        {country && timezones && (
          <FormControl>
            <InputLabel id="timezone_label">
              {t(translationModel + ".form.timezone")}
            </InputLabel>
            <Select
              sx={{ marginBottom: "15px" }}
              id="timezone"
              labelId="timezone_label"
              value={timezone}
              label={t(translationModel + ".form.timezone")}
              onChange={timezoneChangeHandler}
              onBlur={timezoneBlurHandler}
              disabled={action === "view"}
            >
              {timezones.map((timezone, index) =>
                createTimezones(timezone.name, index)
              )}
            </Select>
          </FormControl>
        )}
        <FileUploader
          action={action}
          currentFile={logo}
          fileType="image"
          inputId="logo"
          inputLabel={t(translationModel + ".form.companyLogo")}
          token={token}
          onUploadSuccess={uploadSuccessHandler}
        ></FileUploader>
        <div sx={{ marginTop: "35px" }}></div>
        <FormControl variant="outlined">
          <InputLabel htmlFor="ticketLabel">
            {t(translationModel + ".form.ticketLabel")}
          </InputLabel>
          <FilledInput
            multiline
            sx={{ background: "white" }}
            id="ticketLabel"
            onChange={ticketLabelChangeHandler}
            onBlur={ticketLabelBlurHandler}
            value={ticketLabel}
            endAdornment={
              ticketLabel !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetTicketLabel} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.ticketLabel")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="termsAndConditions">
            {t(translationModel + ".form.termsAndConditions")}
          </InputLabel>
          <FilledInput
            multiline
            sx={{ background: "white" }}
            id="termsAndConditions"
            onChange={termsAndConditionsChangeHandler}
            onBlur={termsAndConditionsBlurHandler}
            value={termsAndConditions}
            endAdornment={
              termsAndConditions !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetTermsAndConditions} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.termsAndConditions")}
            disabled={action === "view"}
          />
        </FormControl>
        <Divider />
        <Typography align="center">
          {t(translationModel + ".form.settings.title")}
        </Typography>
        <FormControlLabel
          control={
            <Switch
              id="taxationAfterDiscount"
              checked={
                taxationAfterDiscount === 1 || taxationAfterDiscount === true
                  ? true
                  : false
              }
              onChange={taxationAfterDiscountChangeHandler}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={t(translationModel + ".form.settings.taxationAfterDiscount")}
          disabled={action === "view"}
        />
        <FormControlLabel
          control={
            <Switch
              id="printLogoOnTicket"
              checked={
                printLogoOnTicket === 1 || printLogoOnTicket === true
                  ? true
                  : false
              }
              onChange={printLogoOnTicketChangeHandler}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={t(translationModel + ".form.settings.printLogoOnTicket")}
          disabled={action === "view"}
        />
        <FormControlLabel
          control={
            <Switch
              id="sendMailNotifications"
              checked={
                sendMailNotifications === 1 || sendMailNotifications === true
                  ? true
                  : false
              }
              onChange={sendMailNotificationsChangeHandler}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={t(
            translationModel + ".form.settings.setSendMailNotifications"
          )}
          disabled={action === "view"}
        />
        {action === "view" && (
          <Button
            id="edit_form"
            variant="contained"
            onClick={formEditHandler}
            disabled={!formIsValid}
          >
            {t("buttons.edit", { model: t(translationModel + ".model") })}
          </Button>
        )}
        {action === "edit" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {t("buttons.save")}
          </Button>
        )}
        {action === "edit" && (
          <Button
            id="cancel_form"
            variant="contained"
            color={action === "view" ? "inherit" : "warning"}
            onClick={cancelHandler}
          >
            {action !== "view" && t("buttons.cancel")}
          </Button>
        )}
      </Stack>
    </Fragment>
  );
};

export default ModelForm;
