import React, { useEffect, useState, Fragment } from "react";
import useHttp from "../../hooks/use-http";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Alert,
  Tooltip,
  Fab,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Search, Delete, Add } from "@mui/icons-material";
import styles from "./styles.module.css";

const ModelDetails = ({
  action,
  addModelDetailAction,
  addModelDetailProduct,
  addModelDetailResourceId,
  addModelDetailProductQuantity,
  addModelDetailProductPrice,
  modelData: modelDataProp, //TODO: Check if really needed
  confirmAction,
  modelId,
  module,
  token,
  translationModel,
  onAlertDeleteModelDetail,
  onConfirmResponse,
  onViewProduct,
}) => {
  const { t } = useTranslation("common");
  const [modelData, setModelData] = useState({});
  const editable = useSelector((state) => state.pos.editable);
  const getAmountDue = (model) => {
    return model?.sale?.salAmountDue || model?.purchase?.purAmountDue;
  };
  const getModelIsCancelled = (model) => {
    return model?.sale?.salCancel || model?.purchase?.purCancel;
  };
  const getWarehouse = (model) => {
    return model.sale?.salWarOutput || model.purchase?.purWarInput;
  };
  const amountDue = getAmountDue(modelDataProp);
  const modelIsCanceled = getModelIsCancelled(modelDataProp);
  const warehouse = getWarehouse(modelDataProp);
  let refreshGrid = true;
  const isSalesModule = () => {
    return (
      module === "sales" ||
      module === "salesclient" ||
      module === "posnew_sale" ||
      module === "posnew_saleclient" ||
      module === "salespending_charges"
    );
  };

  const isPurchasesModule = () => {
    return (
      module === "purchases" ||
      module === "posnew_purchase" ||
      module === "purchasespending_payments"
    );
  };

  let columns = [
    { title: t(translationModel + ".columns.saleDetails.name") },
    { title: t(translationModel + ".columns.saleDetails.quantity") },
    { title: t(translationModel + ".columns.saleDetails.price") },
    { title: t(translationModel + ".columns.saleDetails.total") },
    { title: "" },
  ];

  if (isPurchasesModule()) {
    columns = [
      { title: t(translationModel + ".columns.purchaseDetails.name") },
      { title: t(translationModel + ".columns.purchaseDetails.quantity") },
      { title: t(translationModel + ".columns.purchaseDetails.price") },
      { title: t(translationModel + ".columns.purchaseDetails.total") },
      { title: "" },
    ];
  }
  //TODO: Refactor rows
  let rows = [
    {
      content: (params) => (
        <Stack direction="row" spacing={2}>
          <Tooltip
            key={params.id}
            title={<Typography variant="h5">{params.salDetProduct}</Typography>}
            placement="top"
          >
            <Typography
              variant="h7"
              sx={{
                width: "120px",
                maxWidth: "120px",
                paddingRight: "5px",
              }}
            >
              {params.salDetProduct?.length > 30
                ? params.salDetProduct.slice(0, 30) + "..."
                : params.salDetProduct}
            </Typography>
          </Tooltip>
          <Fab
            id={"model_details_view_action_" + params.id}
            className={`${styles["action-buttons"]}`}
            sx={{
              display: "flex",
              justifyContent: "center",
              minWidth: "40px",
              zIndex: "1",
            }}
            size="small"
            aria-label="view"
            onClick={() => viewHandler(params.salDetProductID)}
            disabled={!editable}
          >
            <Search fontSize="small" />
          </Fab>
        </Stack>
      ),
    },
    {
      content: (params) => (
        <Stack direction="row">
          <Fab
            id={"model_details_subtract_action_" + params.id}
            className={`${styles["action-buttons"]}`}
            sx={{ marginRight: "5px", zIndex: "1" }}
            size="small"
            aria-label="view"
            onClick={() =>
              updateProductQuantityHandler(
                "subtract",
                params.id,
                params.salDetProductID,
                params.salDetProductType
              )
            }
            color="warning"
            disabled={
              action === "view" ||
              modelIsCanceled ||
              amountDue === 0 ||
              !editable
                ? true
                : false
            }
          >
            <Typography variant="h5">{"-"}</Typography>
          </Fab>
          <TextField
            id={"model_detail_quantity_" + params.id}
            value={params.salDetQuantity}
            sx={{ width: "45px", paddingRight: "5px" }}
            disabled
          />
          <Fab
            id={"model_details_add_action_" + params.id}
            className={`${styles["action-buttons"]}`}
            sx={{ marginRight: "5px", zIndex: "1" }}
            size="small"
            aria-label="view"
            onClick={() =>
              updateProductQuantityHandler(
                "add",
                params.id,
                params.salDetProductID,
                params.salDetProductType
              )
            }
            color="success"
            disabled={
              action === "view" ||
              modelIsCanceled ||
              amountDue === 0 ||
              !editable
                ? true
                : false
            }
          >
            <Add fontSize="small" />
          </Fab>
        </Stack>
      ),
    },
    {
      content: (params) =>
        params.salDetPrice &&
        "$" + params.salDetPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
    },
    {
      content: (params) =>
        params.salDetPrice &&
        "$" + parseFloat(params.salDetTotalPrice, 2).toFixed(2),
    },
    {
      content: (params) => (
        <Fragment>
          <Fab
            id={"model_details_delete_action_" + params.id}
            className={`${styles["action-buttons"]}`}
            sx={{ marginRight: "10px", zIndex: "1" }}
            size="small"
            aria-label="view"
            onClick={() =>
              deleteHandler(
                params.id,
                params.salDetProductID,
                params.salDetProductType,
                params.salDetQuantity,
                params.salDetSaleID
              )
            }
            color="error"
            disabled={
              action === "view" ||
              modelIsCanceled ||
              amountDue === 0 ||
              !editable
                ? true
                : false
            }
          >
            <Delete fontSize="small" />
          </Fab>
        </Fragment>
      ),
    },
  ];

  if (isPurchasesModule()) {
    rows = [
      {
        content: (params) => (
          <Stack direction="row" spacing={2}>
            <Tooltip
              key={params.id}
              title={
                <Typography variant="h5">{params.purDetProduct}</Typography>
              }
              placement="top"
            >
              <Typography
                variant="h7"
                sx={{
                  width: "120px",
                  maxWidth: "120px",
                  paddingRight: "5px",
                }}
              >
                {params.purDetProduct?.length > 30
                  ? params.purDetProduct.slice(0, 30) + "..."
                  : params.purDetProduct}
              </Typography>
            </Tooltip>
            <Fab
              id={"model_details_view_action_" + params.id}
              className={`${styles["action-buttons"]}`}
              sx={{
                display: "flex",
                justifyContent: "center",
                minWidth: "40px",
                zIndex: "1",
              }}
              size="small"
              aria-label="view"
              onClick={() => viewHandler(params.purDetProductID)}
            >
              <Search fontSize="small" />
            </Fab>
          </Stack>
        ),
      },
      {
        content: (params) => (
          <Stack direction="row">
            <Fab
              id={"model_details_subtract_action_" + params.id}
              className={`${styles["action-buttons"]}`}
              sx={{ marginRight: "5px", zIndex: "1" }}
              size="small"
              aria-label="view"
              onClick={() =>
                updateProductQuantityHandler(
                  "subtract",
                  params.id,
                  params.purDetProductID,
                  params.purDetProductType
                )
              }
              color="warning"
              disabled={
                action === "view" || modelIsCanceled || amountDue === 0
                  ? true
                  : false
              }
            >
              <Typography variant="h5">{"-"}</Typography>
            </Fab>
            <TextField
              id={"model_detail_quantity_" + params.id}
              value={params.purDetQuantity}
              sx={{ width: "45px", paddingRight: "5px" }}
              disabled
            />
            <Fab
              id={"model_details_add_action_" + params.id}
              className={`${styles["action-buttons"]}`}
              sx={{ marginRight: "5px", zIndex: "1" }}
              size="small"
              aria-label="view"
              onClick={() =>
                updateProductQuantityHandler(
                  "add",
                  params.id,
                  params.purDetProductID,
                  params.purDetProductType
                )
              }
              color="success"
              disabled={
                action === "view" || modelIsCanceled || amountDue === 0
                  ? true
                  : false
              }
            >
              <Add fontSize="small" />
            </Fab>
          </Stack>
        ),
      },
      {
        content: (params) =>
          params.purDetPrice &&
          "$" +
            params.purDetPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
      },
      {
        content: (params) =>
          params.purDetTotalPrice &&
          "$" + parseFloat(params.purDetTotalPrice, 2).toFixed(2),
      },
      {
        content: (params) => (
          <Fragment>
            <Fab
              id={"model_details_delete_action_" + params.id}
              className={`${styles["action-buttons"]}`}
              sx={{ marginRight: "10px", zIndex: "1" }}
              size="small"
              aria-label="view"
              onClick={() =>
                deleteHandler(
                  params.id,
                  params.purDetProductID,
                  params.purDetProductType,
                  params.purDetQuantity,
                  params.purDetPurchaseID
                )
              }
              color="error"
              disabled={
                action === "view" || modelIsCanceled || amountDue === 0
                  ? true
                  : false
              }
            >
              <Delete fontSize="small" />
            </Fab>
          </Fragment>
        ),
      },
    ];
  }

  const addModelDetail = async (product, resourceId) => {
    const moduleUrl = isSalesModule() ? "/sale_details" : "/purchase_details";
    const requestUrl =
      process.env.REACT_APP_API_SERVER + moduleUrl + "/add_product";
    let body = {
      salDetSaleID: modelId,
      salDetProductID: product.id,
      salDetProductType: product.proType,
      salDetQuantity:
        addModelDetailProductQuantity > 0 ? addModelDetailProductQuantity : 1,
      salDetPrice: product.proSalePrice,
      salDetTax: product.proTax,
      salDetDiscount: product.proPromoDiscount,
      salDetAction: "add",
      salDetWarehouseId: warehouse,
    };
    if (isPurchasesModule()) {
      body = {
        purDetPurchaseID: modelId,
        purDetProductID: product.id,
        purDetProductType: product.proType,
        purDetQuantity:
          addModelDetailProductQuantity > 0 ? addModelDetailProductQuantity : 1,
        purDetPrice:
          addModelDetailProductPrice > 0
            ? addModelDetailProductPrice
            : product.proSalePrice,
        purDetTax: product.proTax,
        purDetWarehouseId: warehouse,
      };
    }

    await sendModelDetailsCreateRequest({
      url: requestUrl,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body,
    });

    if (!fetchModelDetailsCreateError) {
      getModelDetailsHandler();
      onConfirmResponse("addSuccess");
    }
  };

  const {
    error: fetchDeleteModelDetailError,
    sendRequest: sendDeleteModelDetailRequest,
  } = useHttp();

  const deleteModelDetail = async () => {
    const moduleUrl = isSalesModule() ? "/sale_details" : "/purchase_details";
    const requestUrl = process.env.REACT_APP_API_SERVER + moduleUrl;
    let body = {
      id: modelData.modelDetailId,
      salDetProductType: modelData.productType,
      salDetWarehouseId: warehouse,
      purDetProductID: modelData.productId,
    };

    if (!isSalesModule()) {
      body = {
        id: modelData.modelDetailId,
        purDetProductType: modelData.productType,
        purDetWarehouseId: warehouse,
        purDetProductID: modelData.productId,
      };
    }

    await sendDeleteModelDetailRequest({
      url: requestUrl,
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body,
    });
    !fetchDeleteModelDetailError && onConfirmResponse("deleteSuccess");
    getModelDetailsHandler();
  };

  const deleteHandler = (
    modelDetailId,
    productId,
    type,
    productQuantity,
    modelId
  ) => {
    const modelDetailData = {
      modelDetailId,
      productId,
      productType: type,
      productQuantity,
      modelId,
    };
    setModelData(modelDetailData);
    onAlertDeleteModelDetail(modelDetailData);
  };

  const {
    isLoading: modelDetailsAreLoading,
    error: fetchModelDetailsError,
    data: modelDetails,
    sendRequest: sendModelDetailsGetRequest,
  } = useHttp();

  const getModelDetailsHandler = async () => {
    const moduleUrl = isSalesModule()
      ? process.env.REACT_APP_API_SERVER + "/sale_details/"
      : process.env.REACT_APP_API_SERVER + "/purchase_details/";
    await sendModelDetailsGetRequest({
      url: moduleUrl + modelId,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    error: fetchModelDetailsCreateError,
    sendRequest: sendModelDetailsCreateRequest,
  } = useHttp();

  const updateProductQuantityHandler = async (
    operation,
    modelDetailId,
    productId,
    productType
  ) => {
    const moduleUrl =
      module === "sales" ||
      module === "salesclient" ||
      module === "posnew_sale" ||
      module === "posnew_saleclient"
        ? "/sale_details/update_quantity"
        : "/purchase_details/update_quantity";
    const requestUrl = process.env.REACT_APP_API_SERVER + moduleUrl;

    let body = {
      id: modelDetailId,
      salDetAction: operation,
      salDetProductID: productId,
      salDetProductType: productType,
    };

    if (
      module === "purchases" ||
      module === "posnew_purchase" ||
      module === "purchasespending_payments"
    ) {
      body = {
        id: modelDetailId,
        purDetAction: operation,
        purDetProductID: productId,
        purDetProductType: productType,
      };
    }
    await sendModelDetailsCreateRequest({
      url: requestUrl,
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body,
    });

    if (!fetchModelDetailsCreateError) {
      getModelDetailsHandler();
      onConfirmResponse("addSuccess");
    }
  };

  const viewHandler = (productId) => {
    onViewProduct(productId);
  };

  useEffect(() => {
    addModelDetailAction &&
      addModelDetailProduct &&
      addModelDetail(addModelDetailProduct, addModelDetailResourceId);
    if (refreshGrid) {
      !modelDetails && getModelDetailsHandler();
      // eslint-disable-next-line
      refreshGrid = false;
    }
    confirmAction && deleteModelDetail();
    // eslint-disable-next-line
  }, [
    action,
    addModelDetailAction,
    confirmAction,
    modelId,
    refreshGrid,
    modelDetails,
  ]);

  return (
    <Fragment>
      {modelDetailsAreLoading && <CircularProgress color="inherit" />}
      {fetchModelDetailsError ||
        (fetchDeleteModelDetailError && (
          <Alert severity="error">{t("errors.defaultError")}</Alert>
        ))}
      <Paper id="model_details_paper" className={styles.datagrid}>
        {modelDetails &&
          (modelDetails.saleDetails?.length <= 0 ||
            modelDetails.purchaseDetails?.length <= 0) &&
          !modelDetailsAreLoading && (
            <Alert id="model_details_no_content_alert" severity="warning">
              {t("errors.noContent")}
            </Alert>
          )}
        {modelDetails &&
          (modelDetails.saleDetails?.length ||
            modelDetails.purchaseDetails?.length) && (
            <TableContainer component={Paper} sx={{ maxHeight: 250 }}>
              <Table
                stickyHeader
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        sx={{ fontWeight: "bold" }}
                        key={index}
                        align="center"
                      >
                        {column.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modelDetails.saleDetails?.map((modelDetail) => (
                    <TableRow
                      key={modelDetail.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {rows.map((row, index) => (
                        <TableCell key={index} align="center">
                          {row.content(modelDetail)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                  {modelDetails.purchaseDetails?.map((modelDetail) => (
                    <TableRow
                      key={modelDetail.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {rows.map((row, index) => (
                        <TableCell key={index} align="center">
                          {row.content(modelDetail)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
      </Paper>
    </Fragment>
  );
};

export default ModelDetails;
