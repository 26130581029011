import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  Tooltip,
} from "@mui/material";
import { WhatsApp } from "@mui/icons-material/";
import { format, isPast } from "date-fns";
import { DataGrid } from "@mui/x-data-grid";
import GridToolbar from "../UI/ModelGridToolbar";
import GridActions from "../UI/GridActions";

const ModelGrid = (props) => {
  const [alertDelete, setAlertDelete] = useState(false);
  const [Id, setId] = useState(null);
  const [rowCount, setRowCount] = useState(null);
  const [page, setPage] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [search, setSearch] = useState(null);
  const { t } = useTranslation("common");
  const location = useLocation();
  const action = props.action;
  const modelRows = props.modelRows;
  const isLoading = props.isLoading;
  const smallScreen = props.smallScreen;
  const modelName = props.modelName;
  const translationModel = props.translationModel;
  const isReport = props.isReport;
  const rowsPerPage = isReport
    ? JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE_REPORTS)
    : JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE_MODULES);
  const type = props.type;

  const closeAlertDialog = () => {
    setAlertDelete(false);
  };

  const deleteHandler = (Id) => {
    setAlertDelete(true);
    setId(Id);
  };

  const confirmDelete = () => {
    setAlertDelete(false);
    props.onDeleteModel(Id);
  };

  const viewHandler = (Id) => {
    props.onViewModel(Id);
  };

  const editHandler = (Id) => {
    props.onEditModel(Id);
  };

  const refreshGridHandler = () => {
    props.onRefreshGrid();
  };

  const searchHandler = (searchText) => {
    props.onSearch(searchText);
  };

  const pageChangeHandler = (newPage, size, search) => {
    props.onPageChange(newPage, size, search);
  };

  const pageSizeChangeHandler = (size, search) => {
    props.onPageSizeChange(size, search);
  };

  const renderDataGrid = (modelRows, rowCount, page, pageSize) => {
    return modelRows?.bookings.length && rowCount && page >= 0 && pageSize;
  };

  const notificationHandler = (data) => {
    const phoneNumber = data.row.Client.cliWhatsapp;
    const message = t(translationModel + ".columns.notificationMessage", {
      client: data?.row?.Client?.cliFirstName ?? "client",
      date: format(new Date(data?.row?.start), "dd/MM/yy"),
      time: format(new Date(data?.row?.start), "HH:mm aaaa"),
      therapist: data?.row?.therapist?.resName ?? "NA",
      service: data?.row?.Product?.proCommercialName ?? "NA",
    });
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappLink, "_blank");
  };

  const columns = [
    {
      field: "id as id2",
      headerName: t(translationModel + ".columns.actions"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 150,
      disableExport: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <GridActions
          id={params.row.id}
          onViewModelHandler={viewHandler}
          onEditModelHandler={editHandler}
          onDeleteModelHandler={deleteHandler}
          modelName={modelName}
        />
      ),
    },
    {
      field: "id",
      headerName: t(translationModel + ".columns.id"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 20,
      maxWidth: 60,
      type: "number",
      filterable: false,
      sortable: false,
    },
    {
      field: "client",
      headerName: t(translationModel + ".columns.client"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        if (location.pathname !== "/bookings/conflicts") {
          const { Client } = params.row;
          return Client ? `${Client.cliFirstName} ${Client.cliLastName}` : "";
        } else {
          const { Client } = params.row;
          return Client ? `${Client} ` : "";
        }
      },
      renderCell: (params) => {
        if (location.pathname !== "/bookings/conflicts") {
          const { Client } = params.row;
          return Client ? (
            <Tooltip
              title={`${Client.cliFirstName} ${Client.cliLastName}`}
              placement="top"
            >
              <div>{`${Client.cliFirstName} ${Client.cliLastName}`}</div>
            </Tooltip>
          ) : (
            ""
          );
        } else {
          const { Client } = params.row;
          return Client ? (
            <Tooltip title={Client} placement="top">
              <div>{Client}</div>
            </Tooltip>
          ) : (
            ""
          );
        }
      },
    },
    {
      field: "service",
      headerName: t(translationModel + ".columns.service"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        if (location.pathname !== "/bookings/conflicts") {
          return params?.row?.Product?.proCommercialName ? (
            <Tooltip
              title={params?.row?.Product?.proCommercialName}
              placement="top"
            >
              <div>{params?.row?.Product?.proCommercialName}</div>
            </Tooltip>
          ) : (
            ""
          );
        } else {
          return params?.row?.Product ? (
            <Tooltip title={params?.row?.Product} placement="top">
              <div>{params?.row?.Product}</div>
            </Tooltip>
          ) : (
            ""
          );
        }
      },
    },
    {
      field: "employee",
      headerName: t(translationModel + ".columns.employee"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        if (location.pathname !== "/bookings/conflicts") {
          return params?.row?.therapist?.resName || "";
        } else {
          return params?.row?.therapist || "";
        }
      },
      renderCell: (params) => {
        if (location.pathname !== "/bookings/conflicts") {
          return params?.row?.therapist?.resName ? (
            <Tooltip title={params?.row?.therapist?.resName} placement="top">
              <div>{params?.row?.therapist?.resName}</div>
            </Tooltip>
          ) : (
            ""
          );
        } else {
          return params?.row?.therapist ? (
            <Tooltip title={params?.row?.therapist} placement="top">
              <div>{params?.row?.therapist}</div>
            </Tooltip>
          ) : (
            ""
          );
        }
      },
    },
    {
      field: "equipment",
      headerName: t(translationModel + ".columns.equipment"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        if (location.pathname !== "/bookings/conflicts") {
          return params?.row?.equipment?.resName || "";
        } else {
          return params?.row?.equipment || "";
        }
      },
    },
    {
      field: "cabin",
      headerName: t(translationModel + ".columns.cabin"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        if (location.pathname !== "/bookings/conflicts") {
          return params?.row?.room?.resName || "";
        } else {
          return params?.row?.room || "";
        }
      },
    },
    {
      field: "date",
      headerName: t(translationModel + ".columns.date"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 80,
      filterable: false,
      sortable: false,
      valueGetter: (params) => format(new Date(params.row.start), "dd/MM/yy"),
    },
    {
      field: "start",
      headerName: t(translationModel + ".columns.timeStart"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 80,
      filterable: false,
      sortable: false,
      valueGetter: (params) => format(new Date(params.row.start), "HH:mm"),
    },
    {
      field: "end",
      headerName: t(translationModel + ".columns.timeEnd"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 80,
      filterable: false,
      sortable: false,
      valueGetter: (params) => format(new Date(params.row.end), "HH:mm"),
    },
    {
      field: "status",
      headerName: t(translationModel + ".columns.status"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 20,
      maxWidth: 150,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        if (location.pathname !== "/bookings/conflicts") {
          return params?.row?.status_name?.sesStaName || "";
        } else {
          return params?.row?.status_name || "";
        }
      },
    },
    {
      field: "sale_id",
      headerName: t(translationModel + ".columns.saleId"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 20,
      maxWidth: 150,
      type: "number",
      filterable: false,
      sortable: false,
    },
    {
      field: "online",
      headerName: t(translationModel + ".columns.online"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 20,
      maxWidth: 150,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Switch
          id={"online" + params.row.id}
          checked={
            params.row.online === 1 || params.row.online === true ? true : false
          }
          disabled
        />
      ),
    },
    {
      field: "executive",
      headerName: t(translationModel + ".columns.executive"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 20,
      maxWidth: 150,
      filterable: false,
      sortable: false,
      valueGetter: (params) => {
        if (location.pathname !== "/bookings/conflicts") {
          return params?.row?.Executive?.exeInitials || "";
        } else {
          return params?.row?.Executive || "";
        }
      },
    },
  ];

  isReport && columns.shift();
  type !== "past" &&
    columns.push({
      field: "id as id3",
      headerName: t(translationModel + ".columns.notify"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 150,
      disableExport: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Button
          color="success"
          id="whatsapp_notification"
          variant="contained"
          onClick={() => notificationHandler(params)}
          disabled={
            !params?.row?.Client?.cliWhatsapp ||
            isPast(new Date(params?.row?.start))
          }
        >
          <WhatsApp fontSize="small" sx={{ paddingRight: "10px" }} />
          {t(translationModel + ".columns.notify")}
        </Button>
      ),
    });

  const CustomToolbar = () => {
    return (
      <GridToolbar
        action={action}
        isReport={isReport}
        isServerPagination={true}
        search={search}
        modelName={modelName}
        translationModel={translationModel}
        smallScreen={smallScreen}
        onRefreshGrid={refreshGridHandler}
        onSearch={searchHandler}
      />
    );
  };

  useEffect(() => {
    modelRows?.totalItems && setRowCount(modelRows?.totalItems);
    if (modelRows?.page) {
      modelRows?.page > 0
        ? setPage(parseInt(modelRows?.page - 1), 10)
        : setPage(parseInt(0, 10));
    }
    modelRows?.pageSize > 0
      ? setPageSize(parseInt(modelRows?.pageSize, 10))
      : setPageSize(5);
    modelRows?.search && setSearch(modelRows.search);
  }, [modelRows]);

  return (
    <Fragment>
      <Dialog
        id="alert_delete"
        open={alertDelete}
        onClose={closeAlertDialog}
        aria-labelledby="alert-delete-title"
        aria-describedby="alert-delete-description"
      >
        <DialogTitle id="alert-delete-title">
          {t("confirmations.deleteConfirmTitle", {
            model: t(translationModel + ".model"),
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-delete-description">
            {t("confirmations.deleteConfirmDescription", {
              model: t(translationModel + ".model"),
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="alert_cancel_button" onClick={closeAlertDialog}>
            {t("buttons.cancel")}
          </Button>
          <Button id="alert_delete_button" onClick={confirmDelete} autoFocus>
            {t("buttons.accept")}
          </Button>
        </DialogActions>
      </Dialog>

      {!modelRows.bookings.length && !isLoading && (
        <Alert severity="warning">{t("errors.noContent")}</Alert>
      )}
      {renderDataGrid(modelRows, rowCount, page, pageSize) && (
        <DataGrid
          paginationMode="server"
          rows={modelRows.bookings}
          columns={columns}
          rowCount={rowCount}
          page={page}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) =>
            pageSizeChangeHandler(newPageSize, search && search)
          }
          onPageChange={(newPage) =>
            pageChangeHandler(
              newPage,
              pageSize > 0 ? pageSize : 5,
              search && search
            )
          }
          rowsPerPageOptions={rowsPerPage.map(Number)}
          components={{
            Toolbar: CustomToolbar,
          }}
          initialState={{
            sorting: { sortModel: [{ field: "id", sort: "desc" }] },
            columns: {},
          }}
        />
      )}
    </Fragment>
  );
};

export default ModelGrid;
