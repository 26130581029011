import { createSlice } from "@reduxjs/toolkit";

const initialUiState = {
  lang: localStorage.getItem("language") || "es",
  pageSize: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
  page: 0,
  purchaseId: "",
  sort: { field: "id", sort: "desc" },
  saleId: "",
  timeZone: process.env.REACT_APP_TIME_ZONE,
  module: "",
  action: "",
  id: null,
  filter: null,
};

const uiSlice = createSlice({
  name: "ui",
  initialState: initialUiState,
  reducers: {
    changeLanguage(state, action) {
      localStorage.setItem("language", JSON.stringify(action.payload));
      state.lang = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setPurchaseId(state, action) {
      state.purchaseId = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
    setSort(state, action) {
      state.sort = { field: action.payload, sort: "desc" };
    },
    setSaleId(state, action) {
      state.saleId = action.payload;
    },
    setModule(state, action) {
      state.module = action.payload;
    },
    setAction(state, action) {
      state.action = action.payload;
    },
    setId(state, action) {
      state.action = action.payload;
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
