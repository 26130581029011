//TODO: Configure routes
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { uiActions } from "../../store/ui-slice";
import i18next from "i18next";
import {
  Menu,
  MenuItem,
  ListItemText,
  Typography,
  IconButton,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";

const HeaderLanguageMenu = () => {
  const dispatch = useDispatch();
  const [activeMenu, setActiveMenu] = useState(null);
  const openMenu = Boolean(activeMenu);
  const language = JSON.parse(localStorage.getItem("language"));

  const menuHandler = (event) => {
    setActiveMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setActiveMenu(null);
  };

  const languageHandler = (lang) => {
    dispatch(uiActions.changeLanguage(lang));
    closeMenu();
  };

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);

  return (
    <Fragment>
      <IconButton
        id="language-button"
        aria-controls={openMenu ? "language-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
        onClick={menuHandler}
        color="inherit"
      >
        <LanguageIcon />
        <Typography sx={{ paddingLeft: "5px" }}>
          {language ? language : i18next.language}
        </Typography>
      </IconButton>
      <Menu
        id="language-menu"
        anchorEl={activeMenu}
        open={openMenu}
        onClose={() => closeMenu()}
        MenuListProps={{
          "aria-labelledby": "language-button",
        }}
      >
        <MenuItem onClick={() => languageHandler("en")}>
          <ListItemText>EN</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => languageHandler("es")}>
          <ListItemText>ES</ListItemText>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
export default HeaderLanguageMenu;
