import React, { Fragment, useEffect, useState } from "react";
import useInput from "../../hooks/use-input";
import {
  Stack,
  Fab,
  FormControl,
  FormControlLabel,
  InputLabel,
  Button,
  InputAdornment,
  Typography,
  FilledInput,
  FormHelperText,
  Select,
  MenuItem,
  Switch,
} from "@mui/material";
import i18next from "i18next";
import { Clear } from "@mui/icons-material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

const ModelForm = ({
  action,
  model,
  translationModel,
  onCreate,
  onUpdate,
  onCancel,
}) => {
  const { t } = useTranslation("common");
  let loadForm = true;
  const [inactive, setInactive] = useState(false);
  const types = ["1", "2", "3"];

  const {
    enteredValue: type,
    setEnteredValue: setType,
    valueIsValid: typeIsValid,
    valueChangeHandler: typeChangeHandler,
    hasError: typeHasError,
    inputBlurHandler: typeBlurHandler,
    reset: resetType,
  } = useInput((value) => value.toString().trim() !== "");

  const findTranslation = (key, index) => {
    if (i18next.exists(`common:paymentTypes.form.types.${key}`)) {
      return (
        <MenuItem key={index} value={key}>
          {t([`paymentTypes.form.types.${key}`])}
        </MenuItem>
      );
    }
  };

  const {
    enteredValue: name,
    setEnteredValue: setName,
    valueIsValid: nameIsValid,
    valueChangeHandler: nameChangeHandler,
    hasError: nameHasError,
    inputBlurHandler: nameBlurHandler,
    reset: resetName,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: description,
    setEnteredValue: setDescription,
    valueChangeHandler: descriptionChangeHandler,
    hasError: descriptionHasError,
    inputBlurHandler: descriptionBlurHandler,
    reset: resetDescription,
  } = useInput();

  const inactiveChangeHandler = () => {
    setInactive(!inactive);
  };

  const resetInactive = () => {
    setInactive("");
  };

  const {
    enteredValue: captured,
    setEnteredValue: setCaptured,
    reset: resetCaptured,
  } = useInput();

  let formIsValid = false;

  if (typeIsValid && nameIsValid) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    let inactiveValue = null;

    if (inactive === true) {
      inactiveValue = 1;
    }
    if (inactive === false) {
      inactiveValue = null;
    }

    if (action === "add") {
      const modelData = {
        payTypType: type,
        payTypName: name,
        payTypDescription: description,
        payTypInactive: inactiveValue,
      };
      onCreate(modelData);
    }

    if (action === "edit") {
      const modelData = {
        id: model.PaymentType.id,
        payTypType: type,
        payTypName: name,
        payTypDescription: description,
        payTypInactive: inactiveValue,
      };
      onUpdate(modelData);
    }
    resetType();
    resetName();
    resetDescription();
    resetInactive();
    resetCaptured();
  };

  const cancelHandler = () => {
    onCancel();
  };

  useEffect(() => {
    if (loadForm) {
      if (model && action) {
        setType(
          model.PaymentType.payTypType ? model.PaymentType.payTypType : ""
        );
        setName(
          model.PaymentType.payTypName ? model.PaymentType.payTypName : ""
        );
        setDescription(
          model.PaymentType.payTypDescription
            ? model.PaymentType.payTypDescription
            : ""
        );
        if (
          model.PaymentType.payTypInactive === 1 ||
          model.PaymentType.payTypInactive === true
        ) {
          setInactive(true);
        }
        if (!model.PaymentType.payTypInactive) {
          setInactive(false);
        }
        setCaptured(
          model.PaymentType.payTypCaptured
            ? model.PaymentType.payTypCaptured
            : ""
        );
      }
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model]);

  return (
    <Fragment>
      <Stack
        className={`${styles["client-form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        <FormControl error={typeHasError ? true : false} variant="outlined">
          <InputLabel id="type_label">
            {t(translationModel + ".form.type")}*
          </InputLabel>
          <Select
            id="type"
            labelId="type_label"
            value={type}
            label={t(translationModel + ".form.type")}
            onChange={typeChangeHandler}
            onBlur={typeBlurHandler}
            disabled={action === "view"}
          >
            {types.map((type, index) => findTranslation(type, index))}
          </Select>
          {typeHasError && (
            <FormHelperText id="name-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl error={nameHasError ? true : false} variant="outlined">
          <InputLabel htmlFor="name">
            {t(translationModel + ".form.name")}*
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="name"
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
            value={name}
            endAdornment={
              name !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetName} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.name")}
            disabled={action === "view"}
          />
          {nameHasError && (
            <FormHelperText id="name-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl
          error={descriptionHasError ? true : false}
          variant="outlined"
        >
          <InputLabel htmlFor="description">
            {t(translationModel + ".form.description")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white" }}
            id="description"
            onChange={descriptionChangeHandler}
            onBlur={descriptionBlurHandler}
            value={description}
            endAdornment={
              description !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetDescription} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.description")}
            disabled={action === "view"}
          />
          {descriptionHasError && (
            <FormHelperText id="description-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              id="inactive"
              checked={inactive === 1 || inactive === true ? true : false}
              onChange={inactiveChangeHandler}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={t(translationModel + ".form.inactive")}
        />
        {action === "view" && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="captured">
              {t(translationModel + ".form.captured")}
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="captured"
              value={captured}
              type="text"
              label={t(translationModel + ".form.captured")}
              disabled
            />
          </FormControl>
        )}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ModelForm;
