import React, { Fragment, useEffect, useState } from "react";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import { useSelector } from "react-redux";
import {
  Button,
  CircularProgress,
  Fab,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  addDays,
  addMinutes,
  areIntervalsOverlapping,
  differenceInMinutes,
  eachMinuteOfInterval,
  format,
  isSameDay,
  isSameHour,
  isSameMinute,
  parseISO,
  setHours,
  setMinutes,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { Clear } from "@mui/icons-material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

const ModelForm = ({
  model,
  translationModel,
  action,
  hour: bookingHour,
  resource: bookingResource,
  bookingId,
  resourceType: bookingResourceType,
  resourceBookings,
  clientId,
  onCreate,
  onUpdate,
  onCancel,
}) => {
  const { t } = useTranslation("common");
  const token = useSelector((state) => state.auth.token);
  const [availableSlots, setAvailableSlots] = useState();
  const timeZone = process.env.REACT_APP_TIME_ZONE;
  const companyWorkingHours = [7, 22]; //TODO: Get from company config
  let loadForm = true;
  const {
    data: clients,
    isLoading: clientsAreLoading,
    sendRequest: fetchClients,
  } = useHttp();

  const getClients = () => {
    fetchClients({
      url: process.env.REACT_APP_API_SERVER + "/Clients",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: client,
    setEnteredValue: setClient,
    valueIsValid: clientIsValid,
    valueChangeHandler: clientChangeHandler,
    hasError: clientHasError,
    inputBlurHandler: clientBlurHandler,
    reset: resetClient,
  } = useInput((value) => value.toString().trim() !== "");

  const getWorkingHours = (date, startHour, endHour) => {
    const startDateTime = setMinutes(
      setHours(addDays(new Date(date), 1), startHour),
      0
    );
    const endDateTime = setMinutes(
      setHours(addDays(new Date(date), 1), endHour),
      0
    );

    return eachMinuteOfInterval(
      {
        start: startDateTime,
        end: endDateTime,
      },
      { step: 30 }
    );
  };

  const getAvailability = async (date, employee) => {
    let overlappingSlots = [];
    const serviceData = findServiceDuration(service);
    const workingHours = getWorkingHours(
      date,
      companyWorkingHours[0],
      companyWorkingHours[1]
    );
    const bookings = await fetchBookingsByDate(date);
    const dayBookingsByEmployee = bookings.bookings.filter(
      (booking) =>
        isSameDay(new Date(booking.start), addDays(new Date(date), 1)) &&
        booking.resourceType1 === employee
    );

    if (
      dayBookingsByEmployee &&
      dayBookingsByEmployee.length > 0 &&
      serviceData
    ) {
      dayBookingsByEmployee.forEach((booking) => {
        workingHours.forEach((workingHour) => {
          if (
            areIntervalsOverlapping(
              { start: new Date(booking.start), end: new Date(booking.end) },
              {
                start: new Date(workingHour),
                end: new Date(addMinutes(new Date(workingHour), 30)),
              }
            ) ||
            areIntervalsOverlapping(
              { start: new Date(booking.start), end: new Date(booking.end) },
              {
                start: new Date(workingHour),
                end: new Date(
                  addMinutes(new Date(workingHour), serviceData.proDurationMin)
                ),
              }
            )
          ) {
            overlappingSlots.push(workingHour);
          }
        });
      });

      const filteredSlots = workingHours.filter((hour) => {
        return !overlappingSlots.find((slot) =>
          isSameMinute(new Date(slot), new Date(hour))
        );
      });

      if (action === "add") {
        setAvailableSlots(filteredSlots);
      } else if (action === "view" || action === "edit") {
        const bookingSlots = eachMinuteOfInterval(
          {
            start: new Date(`${dateStart}T${timeStart}`),
            end: new Date(dateTimeEnd),
          },
          { step: 30 }
        );
        bookingSlots.map(
          (booking) =>
            !filteredSlots.find(
              (slot) =>
                isSameHour(new Date(booking), new Date(slot)) &&
                isSameMinute(new Date(booking), new Date(slot))
            ) && filteredSlots.push(new Date(booking))
        );
        setAvailableSlots(filteredSlots.sort());
      }
    } else {
      setAvailableSlots(workingHours);
    }
  };

  const {
    enteredValue: dateStart,
    setEnteredValue: setDateStart,
    valueIsValid: dateStartIsValid,
    hasError: dateStartHasError,
    inputBlurHandler: dateStartBlurHandler,
    reset: resetDateStart,
  } = useInput((value) => value.toString().trim() !== "");

  const fetchBookingsByDate = async (date) => {
    const [year, month, day] = date.split("-");
    const start = format(new Date(year, month - 1, day), "yyyy-MM-dd");
    const end = format(
      addDays(new Date(year, month - 1, day), 1),
      "yyyy-MM-dd"
    );

    const response = await fetch(
      process.env.REACT_APP_API_SERVER + "/bookings",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          start: start,
          end: end,
        }),
      }
    );
    const data = await response.json();
    return data;
  };

  const dateStartChangeHandler = async (event) => {
    const dateStartValue = event.target.value;
    setDateStart(dateStartValue);
    employee && getAvailability(dateStartValue, employee);
    findServiceDuration(service, dateStartValue, timeStart);
  };

  const {
    enteredValue: timeStart,
    setEnteredValue: setTimeStart,
    valueIsValid: timeStartIsValid,
    inputBlurHandler: timeStartBlurHandler,
    reset: resetTimeStart,
  } = useInput((value) => value.toString().trim() !== "");

  const timeStartChangeHandler = (event) => {
    setTimeStart(event.target.value);
    findServiceDuration(service, dateStart, event.target.value);
  };

  const {
    enteredValue: dateTimeEnd,
    setEnteredValue: setDateTimeEnd,
    valueChangeHandler: dateTimeEndChangeHandler,
    hasError: dateTimeEndHasError,
    inputBlurHandler: dateTimeEndBlurHandler,
    reset: resetDateTimeEnd,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    data: services,
    isLoading: servicesAreLoading,
    sendRequest: fetchServices,
  } = useHttp();

  const getServices = () => {
    fetchServices({
      url: process.env.REACT_APP_API_SERVER + "/products?type=service",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const filterServices = () => {
    if (resourceBookings?.length > 0) {
      const nextBooking = resourceBookings.find(
        (booking) => booking && new Date(booking.start) >= new Date(bookingHour)
      );
      if (nextBooking) {
        const spaceBeforeNextBooking = differenceInMinutes(
          new Date(nextBooking.start),
          new Date(bookingHour)
        );
        return services.Products.filter((service) => {
          return service.proDurationMin <= spaceBeforeNextBooking;
        });
      }
    }

    if (dateStart && timeStart) {
      const workingHours = getWorkingHours(
        dateStart,
        companyWorkingHours[0],
        companyWorkingHours[1]
      );
      const lastWorkingHour = workingHours[workingHours.length - 1];
      const bookingSpace = differenceInMinutes(
        new Date(lastWorkingHour),
        new Date(`${dateStart}T${timeStart}`)
      );
      return services.Products.filter(
        (service) => service.proDurationMin <= bookingSpace
      );
    }
  };

  const {
    enteredValue: service,
    setEnteredValue: setService,
    hasError: serviceHasError,
    inputBlurHandler: serviceBlurHandler,
    reset: resetService,
  } = useInput((value) => value.toString().trim() !== "");

  const serviceChangeHandler = async (event) => {
    setService(event.target.value);
    dateStart &&
      timeStart &&
      findServiceDuration(event.target.value, dateStart, timeStart);
    dateStart && employee && getAvailability(dateStart, employee);
    const serviceData = findServiceData(event.target.value);

    if (serviceData) {
      setSalSubTotalAmount(serviceData.proSalePrice);
      setSalTotalTaxes(serviceData.proTax); //Percentage in decimals
      setSalTotalAmount(serviceData.proSaleGrossPrice);
      setSalDiscount(serviceData.proPromoDiscount); //Percentage in decimals
    }
  };

  const useResourceCategory = (category) => {
    const {
      data: resources,
      isLoading: resourcesAreLoading,
      sendRequest: fetchResources,
    } = useHttp();

    const getResources = () => {
      fetchResources({
        url:
          process.env.REACT_APP_API_SERVER + `/resources?category=${category}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      });
    };

    return { resources, resourcesAreLoading, getResources };
  };

  const {
    enteredValue: employee,
    setEnteredValue: setEmployee,
    valueIsValid: employeeIsValid,
    hasError: employeeHasError,
    inputBlurHandler: employeeBlurHandler,
    reset: resetEmployee,
  } = useInput((value) => value.toString().trim() !== "");

  const employeeChangeHandler = async (event) => {
    setEmployee(event.target.value);
    dateStart && getAvailability(dateStart, event.target.value);
  };

  const {
    resources: personnel,
    resourcesAreLoading: personnelIsLoading,
    getResources: getPersonnel,
  } = useResourceCategory(1);

  const {
    enteredValue: cabin,
    setEnteredValue: setCabin,
    valueChangeHandler: cabinChangeHandler,
    inputBlurHandler: cabinBlurHandler,
    reset: resetCabin,
  } = useInput();

  const {
    resources: cabins,
    resourcesAreLoading: cabinsAreLoading,
    getResources: getCabins,
  } = useResourceCategory(3);

  const {
    enteredValue: equipment,
    setEnteredValue: setEquipment,
    valueChangeHandler: equipmentChangeHandler,
    inputBlurHandler: equipmentBlurHandler,
    reset: resetEquipment,
  } = useInput();

  const {
    resources: equipments,
    resourcesAreLoading: equipmentsAreLoading,
    getResources: getEquipments,
  } = useResourceCategory(2);

  const {
    data: statuses,
    isLoading: statusesAreLoading,
    sendRequest: fetchStatuses,
  } = useHttp();

  const getStatuses = () => {
    fetchStatuses({
      url: process.env.REACT_APP_API_SERVER + "/session_status",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: status,
    setEnteredValue: setStatus,
    valueChangeHandler: statusChangeHandler,
    inputBlurHandler: statusBlurHandler,
    reset: resetStatus,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: observations,
    setEnteredValue: setObservations,
    valueChangeHandler: observationsChangeHandler,
    inputBlurHandler: observationsBlurHandler,
    reset: resetObservations,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: salTotalAmount,
    setEnteredValue: setSalTotalAmount,
    reset: resetSalTotalAmount,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: salTotalTaxes,
    setEnteredValue: setSalTotalTaxes,
    reset: resetSalTotalTaxes,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: salDiscount,
    setEnteredValue: setSalDiscount,
    reset: resetSalDiscount,
  } = useInput();

  const {
    enteredValue: salSubTotalAmount,
    setEnteredValue: setSalSubTotalAmount,
    reset: resetSalSubTotalAmount,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: captured,
    setEnteredValue: setCaptured,
    reset: resetCaptured,
  } = useInput();

  const {
    enteredValue: createSale,
    setEnteredValue: setCreateSale,
    reset: resetCreateSale,
  } = useInput();

  let formIsValid = false;

  if (
    clientIsValid &&
    dateStartIsValid &&
    timeStartIsValid &&
    dateTimeEnd &&
    employeeIsValid
  ) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();

    if (action === "add") {
      const modelData = {
        description: observations,
        start: utcToZonedTime(dateStart + " " + timeStart, timeZone),
        end: utcToZonedTime(dateTimeEnd, timeZone),
        client_id: client,
        status: status ? status : null,
        resourceType1: employee,
        resourceType2: equipment ? equipment : null,
        resourceType3: cabin ? cabin : null,
        service: service,
        online: 0,
        salTotalAmount: salTotalAmount,
        salTotalTaxes: salTotalTaxes,
        salDiscount: salDiscount,
        salSubTotalAmount: salSubTotalAmount,
        createSale: createSale,
      };
      onCreate(modelData);
    }

    if (action === "edit") {
      const modelData = {
        id: model.booking.id,
        description: observations,
        start: utcToZonedTime(dateStart + " " + timeStart, timeZone),
        end: utcToZonedTime(dateTimeEnd, timeZone),
        client_id: client,
        status: status ? status : null,
        resourceType1: employee,
        resourceType2: equipment ? equipment : null,
        resourceType3: cabin ? cabin : null,
        service: service,
      };
      onUpdate(modelData);
    }
    resetClient();
    resetDateStart();
    resetTimeStart();
    resetDateTimeEnd();
    resetService();
    resetEmployee();
    resetCabin();
    resetEquipment();
    resetStatus();
    resetObservations();
    resetSalTotalAmount();
    resetSalTotalTaxes();
    resetSalDiscount();
    resetSalSubTotalAmount();
    resetCaptured();
    resetCreateSale();
  };

  const cancelHandler = () => {
    onCancel();
  };

  const createSaleChangeHandler = () => {
    setCreateSale(!createSale);
  };

  const findServiceData = (serviceId) => {
    if (services) {
      return services.Products?.find((service) => service.id === serviceId);
    }
  };

  const findServiceDuration = (serviceId, startDate, startTime) => {
    const serviceData = findServiceData(serviceId);
    if (startDate && startTime && serviceData) {
      const dateTimeStart = format(
        new Date(startDate + "T" + startTime),
        "yyyy-MM-dd HH:mm"
      );
      if (serviceData.proDurationMin) {
        const newDateTimeEnd = addMinutes(
          parseISO(dateTimeStart),
          serviceData.proDurationMin
        );
        setDateTimeEnd(format(new Date(newDateTimeEnd), "yyyy-MM-dd HH:mm"));
      }
    } else {
      return serviceData;
    }
  };

  useEffect(() => {
    if (loadForm) {
      !services && getServices();
      if (servicesAreLoading) {
        return;
      }
      !clients && getClients();
      !personnel && getPersonnel();
      !equipment && getEquipments();
      !cabins && getCabins();
      !statuses && getStatuses();

      if (bookingHour) {
        setDateStart(format(bookingHour, "yyyy-MM-dd"));
        setTimeStart(format(bookingHour, "HH:mm"));
      }
      if (bookingResource && bookingResourceType === "personnel") {
        setEmployee(bookingResource);
      }
      if (bookingResource && bookingResourceType === "cabin") {
        setCabin(bookingResource);
      }

      if (model && action) {
        const bookingDateStart = model.booking?.start
          ? format(new Date(model.booking.start), "yyyy-MM-dd")
          : "";
        const bookingTimeStart = model.booking?.start
          ? format(new Date(model.booking.start), "HH:mm")
          : "";
        const bookingDateTimeEnd = model.booking?.end
          ? format(new Date(model.booking.end), "yyyy-MM-dd HH:mm")
          : "";
        setClient(model.booking?.client_id ?? "");
        setDateStart(bookingDateStart ? bookingDateStart : "");
        getAvailability(bookingDateStart, model.booking?.resourceType1);
        setTimeStart(bookingTimeStart ? bookingTimeStart : "");
        setDateTimeEnd(bookingDateTimeEnd ? bookingDateTimeEnd : "");
        setService(model.booking?.service ?? "");
        setEmployee(model.booking?.resourceType1 ?? "");
        setCabin(model.booking?.resourceType3 ?? "");
        setEquipment(model.booking?.resourceType2 ?? "");
        setStatus(model.booking?.status ?? "");
        setObservations(model.booking?.description ?? "");
        setCaptured(model.booking?.Executive?.exeInitials ?? "");
      }
      if (action === "add") {
        !status && setStatus(1);
        !createSale && setCreateSale(true);
      }
      if (clientId && action === "add") {
        setClient(clientId);
      }
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model, service, resourceBookings, servicesAreLoading]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        {clientsAreLoading && <CircularProgress color="inherit" />}
        {clients && (
          <FormControl error={clientHasError ? true : false}>
            <InputLabel id="client_label">
              {t(translationModel + ".form.client")}*
            </InputLabel>
            <Select
              id="client"
              labelId="client_label"
              value={client}
              label={t(translationModel + ".form.client")}
              onChange={clientChangeHandler}
              onBlur={clientBlurHandler}
              disabled={action === "view" || action === "edit"}
            >
              {clients &&
                clients.clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.cliLastName} {client.cliFirstName}
                  </MenuItem>
                ))}
            </Select>
            {clientHasError && (
              <FormHelperText id="type-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        {servicesAreLoading && <CircularProgress color="inherit" />}
        {services && (
          <FormControl error={serviceHasError ? true : false}>
            <InputLabel id="service_label">
              {t(translationModel + ".form.service")}*
            </InputLabel>
            <Select
              id="service"
              labelId="service_label"
              value={service}
              label={t(translationModel + ".form.service")}
              onChange={serviceChangeHandler}
              onBlur={serviceBlurHandler}
              disabled={action === "view" || action === "edit" || !client}
            >
              {services &&
                (resourceBookings?.length > 0
                  ? filterServices().map((service) => (
                      <MenuItem key={service.id} value={service.id}>
                        {`${service.proCommercialName} (${service.proDurationMin} min)`}
                      </MenuItem>
                    ))
                  : services.Products.map((service) => (
                      <MenuItem key={service.id} value={service.id}>
                        {`${service.proCommercialName} (${service.proDurationMin} min)`}
                      </MenuItem>
                    )))}
            </Select>
            {serviceHasError && (
              <FormHelperText id="type-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        {personnelIsLoading && <CircularProgress color="inherit" />}
        {personnel && (!bookingResource || bookingResourceType === "cabin") && (
          <FormControl error={employeeHasError ? true : false}>
            <InputLabel id="employee_label">
              {t(translationModel + ".form.employee")}*
            </InputLabel>
            <Select
              id="employee"
              labelId="employee_label"
              value={employee}
              label={t(translationModel + ".form.employee")}
              onChange={employeeChangeHandler}
              onBlur={employeeBlurHandler}
              disabled={action === "view" || !client || !service}
            >
              {personnel &&
                personnel.resources.map((employee) => (
                  <MenuItem key={employee.id} value={employee.id}>
                    {employee.resName}
                  </MenuItem>
                ))}
            </Select>
            {employeeHasError && (
              <FormHelperText id="type-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        {cabinsAreLoading && <CircularProgress color="inherit" />}
        {cabins && (
          <FormControl>
            <InputLabel id="cabin_label">
              {t(translationModel + ".form.cabin")}
            </InputLabel>
            <Select
              id="cabin"
              labelId="cabin_label"
              value={cabin}
              label={t(translationModel + ".form.cabin")}
              onChange={cabinChangeHandler}
              onBlur={cabinBlurHandler}
              disabled={action === "view" || !client || !service || !employee}
            >
              {cabins &&
                cabins.resources.map((cabin) => (
                  <MenuItem key={cabin.id} value={cabin.id}>
                    {cabin.resName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {equipmentsAreLoading && <CircularProgress color="inherit" />}
        {equipments && (
          <FormControl>
            <InputLabel id="equipment_label">
              {t(translationModel + ".form.equipment")}
            </InputLabel>
            <Select
              id="equipment"
              labelId="equipment_label"
              value={equipment}
              label={t(translationModel + ".form.equipment")}
              onChange={equipmentChangeHandler}
              onBlur={equipmentBlurHandler}
              disabled={action === "view" || !client || !service || !employee}
            >
              {equipments &&
                equipments.resources.map((equipment) => (
                  <MenuItem key={equipment.id} value={equipment.id}>
                    {equipment.resName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {!bookingHour && (
          <TextField
            error={dateStartHasError ? true : false}
            id="dateStart"
            label={t(translationModel + ".form.dateStart") + "*"}
            type="date"
            value={dateStart}
            onChange={dateStartChangeHandler}
            onBlur={dateStartBlurHandler}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={action === "view" || !employee}
            helperText={!dateStartIsValid && t("errors.fieldError")}
          />
        )}

        {!bookingHour && (
          <FormControl>
            <InputLabel id="timeStart_label">
              {t(translationModel + ".form.timeStart")}*
            </InputLabel>
            <Select
              id="timeStart"
              labelId="timeStart_label"
              value={timeStart}
              label={t(translationModel + ".form.timeStart")}
              onChange={timeStartChangeHandler}
              onBlur={timeStartBlurHandler}
              disabled={action === "view" || !employee || !dateStart}
              sx={{ width: 220 }}
            >
              {availableSlots &&
                availableSlots.length > 0 &&
                availableSlots.map((dateTime, key) => (
                  <MenuItem
                    key={key}
                    value={format(new Date(dateTime), "HH:mm")}
                  >
                    {format(dateTime, "HH:mm")}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {bookingHour && (
          <TextField
            id="dateTimeStart"
            label={t(translationModel + ".form.dateTimeStart") + "*"}
            type="datetime-local"
            value={dateStart && timeStart && `${dateStart}T${timeStart}`}
            sx={{ width: 220 }}
            disabled
          />
        )}
        {dateStart && timeStart && (
          <TextField
            error={dateTimeEndHasError ? true : false}
            id="dateTimeEnd"
            label={t(translationModel + ".form.dateTimeEnd") + "*"}
            type="datetime-local"
            value={dateTimeEnd}
            onChange={dateTimeEndChangeHandler}
            onBlur={dateTimeEndBlurHandler}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled
            helperText={dateTimeEndHasError && t("errors.fieldError")}
          />
        )}
        {statusesAreLoading && <CircularProgress color="inherit" />}
        {statuses && (
          <FormControl>
            <InputLabel id="status_label">
              {t(translationModel + ".form.status")}
            </InputLabel>
            <Select
              id="status"
              labelId="status_label"
              value={status}
              label={t(translationModel + ".form.status")}
              onChange={statusChangeHandler}
              onBlur={statusBlurHandler}
              disabled={action === "view" || !client || !service || !employee}
            >
              {statuses &&
                statuses.SessionStatus.map((status) => (
                  <MenuItem key={status.id} value={status.id}>
                    {status.sesStaName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        <FormControl variant="outlined">
          <InputLabel htmlFor="observations">
            {t(translationModel + ".form.observations")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white", height: "80px" }}
            id="observations"
            onChange={observationsChangeHandler}
            onBlur={observationsBlurHandler}
            value={observations}
            endAdornment={
              observations !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetObservations} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.observations")}
            disabled={action === "view"}
          />
        </FormControl>
        {action === "view" && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="captured">
              {t(translationModel + ".form.captured")}
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="captured"
              value={captured}
              type="text"
              label={t(translationModel + ".form.captured")}
              disabled
            />
          </FormControl>
        )}
        {action === "add" && (
          <FormControlLabel
            control={
              <Switch
                id="createSale"
                checked={createSale === 1 || createSale === true ? true : false}
                onChange={createSaleChangeHandler}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={t(translationModel + ".form.createSale")}
          />
        )}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        {!bookingHour && !bookingResource && !bookingId && (
          <Button
            id="cancel_form"
            variant="contained"
            color={action === "view" ? "inherit" : "warning"}
            onClick={cancelHandler}
          >
            {action === "view" && t("buttons.back")}
            {action !== "view" && t("buttons.cancel")}
          </Button>
        )}
      </Stack>
    </Fragment>
  );
};

export default ModelForm;
